import dayjs from 'dayjs';
import TimeZone from 'dayjs/plugin/timezone';
dayjs.extend(TimeZone);

export function formatDate(startDate: string, endDate: string, timezone = 'Asia/Singapore') {
    const startDayjs = dayjs(startDate).tz(timezone);
    const endDayjs = dayjs(endDate).tz(timezone);

    if (startDayjs.format('YYYY-MM-DD') === endDayjs.format('YYYY-MM-DD')) {
        // Same day
        return `${startDayjs.format('MMM D HH:mm')}-${endDayjs.format('HH:mm')} GMT${startDayjs.format('Z')}`;
    } else {
        // Different days
        return `${startDayjs.format('MMM D HH:mm')} - ${endDayjs.format('MMM D HH:mm')} GMT${startDayjs.format('Z')}`;
    }
}

export function getRestTime(endDate: string, len = 1) {
    const diff = dayjs(endDate).diff(dayjs());
    const durationDiff = dayjs.duration(diff);
    const durationDay = durationDiff.days();
    const durationHours = durationDiff.hours();
    const durationMinutes = durationDiff.minutes();
    const durationSeconds = durationDiff.seconds();

    const res: string[] = [];

    if (durationDay > 0) {
        res.push(`${durationDay}d`);
    }

    if (durationHours > 0) {
        res.push(`${durationHours}h`);
    }

    if (durationMinutes > 0) {
        res.push(`${durationMinutes}m`);
    }

    if (durationSeconds > 0) {
        res.push(`${durationSeconds}s`);
    }

    return res.slice(0, len).join(' ');
}
