/* eslint-disable prefer-rest-params */
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class GtagService {
    idAlreadySet = false;

    initializeGtag() {
        window.dataLayer = window.dataLayer || [];
        window.gtag = function (...params: any) {
            window.dataLayer.push(arguments);
        };
        window.gtag('js', new Date());
        window.gtag('config', 'G-FF6SR4HWTH');
    }

    setUserID(id: string) {
        if (id && !this.idAlreadySet && window.gtag) {
            this.idAlreadySet = true;
            window.gtag('set', { user_id: id });
        }
    }

    logEvent(eventName: string, eventParams: any) {
        try {
            window?.gtag('event', eventName, eventParams);
        } catch (err) {
            console.warn('GtagService.logEvent error: ', err);
        }
    }
}
