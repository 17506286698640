import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { CommonService } from '@src/app/services/common.service';
import { copy } from '../../utils/common.util';

@Component({
    selector: 'app-copy',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './copy.component.html',
    styleUrls: ['./copy.component.less'],
})
export class CopyComponent {
    @Input() text = '';
    @Input() reminderMsg = 'Copied!';

    constructor(private commonService: CommonService) {}

    copy() {
        copy(this.text).then(() => this.commonService.success(this.reminderMsg));
    }
}
