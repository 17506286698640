<footer class="flex justify-around items-center gap-x-3 pt-2.5 pb-0 w-full">
    <button *ngIf="cancelText" nz-button class="flex-1 h-10 max-w-[240px]" (click)="cancelClick()">{{cancelText}}</button>
    <button
        *ngIf="confirmText"
        nz-button
        nzType="primary"
        class="flex-1 h-10 max-w-[240px]"
        [nzLoading]="confirming"
        [disabled]="confirmDisabled"
        (click)="okClick()"
        style="transition: none"
    >
        {{confirmText}}
    </button>
</footer>
