import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { combineLatest, fromEvent, Subject, Subscription } from 'rxjs';
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';
import { SearchInputService } from './search-input.service';

@Component({
    selector: 'app-search-input',
    templateUrl: './search-input.component.html',
    styleUrls: ['./search-input.component.less'],
    providers: [SearchInputService],
    animations: [
        trigger('translate', [
            state(
                'from',
                style({
                    transform: 'translateX(100%)',
                })
            ),
            state(
                'to',
                style({
                    transform: 'translateX(0)',
                })
            ),
            transition('from => to', animate(200)),
            transition('to => from', animate(200)),
        ]),
    ],
})
export class SearchInputComponent implements AfterViewInit, OnDestroy {
    @Input() mode: 'desktop' | 'mobile' = 'desktop';
    @Output() goSearchedItem = new EventEmitter();

    @ViewChild('searchInput') searchInput: ElementRef;

    destroy$ = new Subject();
    searchDropDownVisible = false;
    hasData = false;
    searchValue: string;
    searchResult: any[];
    searchBoxTranslate = 'from';

    search$: Subscription;

    constructor(public searchService: SearchInputService) {}

    ngAfterViewInit() {
        fromEvent(this.searchInput.nativeElement, 'input')
            .pipe(
                debounceTime(200),
                switchMap((e: any) => {
                    const value = (e.target as HTMLInputElement).value.trim();

                    return new Promise(resolve => {
                        if (value) {
                            if (this.search$ && !this.search$.closed) {
                                this.search$.unsubscribe();
                            }

                            const allCompleted$ = new Subject();

                            this.search$ = combineLatest([this.searchService.search(value)])
                                .pipe(takeUntil(allCompleted$))
                                .subscribe({
                                    next: data => {
                                        resolve(data[0]);
                                    },
                                    error: () => resolve([]),
                                    complete: () => {
                                        allCompleted$.next(null);
                                        allCompleted$.complete();
                                    },
                                });
                        } else {
                            resolve(null);
                        }
                    });
                }),
                takeUntil(this.destroy$)
            )
            .subscribe((res: any) => {
                if (res) {
                    this.searchDropDownVisible = true;
                    this.hasData = true;
                    // 修改为只支持Address / Txn 搜索
                    this.searchResult = (res || []).filter((resItem: any) => ['transction', 'address'].includes(resItem.type));
                } else {
                    this.hasData = false;
                    this.searchDropDownVisible = false;
                    this.searchResult = [];
                }
            });
    }

    ngOnDestroy() {
        this.destroy$.next(null);
        this.destroy$.complete();
    }

    searchInputFocus() {
        this.searchDropDownVisible = !!this.searchValue;
    }

    showMobileSearch() {
        this.searchBoxTranslate = 'to';
        document.body.style.overflow = 'hidden';
    }

    hideMobileSearch() {
        this.searchBoxTranslate = 'from';
        this.searchValue = null;
        this.hasData = false;
        this.searchResult = [];
        document.body.style.overflow = 'visible';
    }

    itemClicked() {
        this.searchDropDownVisible = false;
        this.goSearchedItem.emit(null);
    }
}
