import { Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { MyProfileService } from '@src/app/modules/my-profile/my-profile.service';
import { WalletStatusService } from '@src/app/services/wallet-status.service';
import dayjs from 'dayjs';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Subject, takeUntil } from 'rxjs';
import { getAge } from '../../utils/common.util';

@Component({
    selector: 'app-all-badges',
    templateUrl: './all-badges.component.html',
    styleUrls: ['./all-badges.component.less'],
})
export class AllBadgesComponent implements OnInit, OnDestroy {
    destroy$ = new Subject<void>();

    isMiningCampaignEnded = false;

    campaignEndDate = dayjs('2024-09-30');

    restDays: string;

    get userProfile() {
        return this.walletStatusService.userProfile;
    }

    constructor(
        private walletStatusService: WalletStatusService,
        private router: Router,
        public myProfileService: MyProfileService,
        @Optional() private modalRef: NzModalRef,
        @Optional() private drawerRef: NzDrawerRef
    ) {}

    ngOnInit() {
        this.router.events.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.modalRef?.close();
            this.drawerRef?.close();
        });

        this.isMiningCampaignEnded = dayjs().isAfter(this.campaignEndDate);

        if (this.isMiningCampaignEnded) {
            this.restDays = 'Campaign Ended.';
        } else {
            this.restDays = getAge(this.campaignEndDate.toString());
        }
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
