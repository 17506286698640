import { SHA256 } from 'crypto-js';

const baseImgUrl = `https://storage.googleapis.com/socialscan-public-asset/image/default_avatar/avatar-tpl-`;

export function hash(input: string, len = 32): string {
    const hashed = SHA256(input);

    // Convert hash to a binary string
    let binaryStr = '';
    for (let i = 0; i < hashed.words.length; i++) {
        const binaryWord = (hashed.words[i] >>> 0).toString(2);
        binaryStr += binaryWord.padStart(len, '0');
    }

    return binaryStr;
}

export function createIdenticon(input: string) {
    const hashedInput = hash(input);

    const index = parseInt(hashedInput.slice(0, 22), 2);
    return `${baseImgUrl}${index % 418}.png`;
}
