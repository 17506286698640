import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-general-modal-header',
    templateUrl: './general-modal-header.component.html',
    styleUrls: ['./general-modal-header.component.less'],
})
export class GeneralModalHeaderComponent {
    @Input() title: string;

    @Output() modalClose = new EventEmitter<void>();

    close() {
        this.modalClose.emit();
    }
}
