import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import en from '@angular/common/locales/en';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import player from 'lottie-web';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { provideLottieOptions } from 'ngx-lottie';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { register } from 'swiper/element/bundle';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GlobalErrorHandler } from './services/global-error-handler';
import { ReuseService } from './services/route-reuse.service';
import { SharedModule } from './shared/shared.module';

registerLocaleData(en);
register();

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        SharedModule,
        MonacoEditorModule.forRoot(),
    ],
    providers: [
        { provide: NZ_I18N, useValue: en_US },
        { provide: RouteReuseStrategy, useClass: ReuseService },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        provideLottieOptions({
            player: () => player,
        }),
    ],

    bootstrap: [AppComponent],
})
export class AppModule {}
