import { Pipe, PipeTransform } from '@angular/core';
import { CommonService } from '@src/app/services/common.service';
import { omitFunc } from './omit.pipe';

@Pipe({
    name: 'address',
})
export class AddressPipe implements PipeTransform {
    constructor(private commonService: CommonService) {}

    transform(value: { [key: string]: any; display_name: string; wallet_address: string; ens_name?: string } | string, endLen = 6): string {
        if (typeof value === 'string') {
            return omitFunc(value, 6, endLen);
        }

        return this.commonService.getAddrName(value);
    }
}
