<div class="colorful-border-grey px-2 py-1 rounded-lg mb-3 w-fit">
    <span class="colorful-text">Add email </span>
</div>

<div [formGroup]="emailForm">
    <div>Email</div>

    <div class="border-b border-solid border-gray-700 mt-5">
        <input nz-input formControlName="email" [nzBorderless]="true" type="email" placeholder="Email" />
    </div>
    <div class="text-xs h-5 py-0.5 text-red-500">
        <ng-container
            *ngIf="emailForm.controls['email'].invalid && (emailForm.controls['email'].dirty || emailForm.controls['email'].touched)"
        >
            <div *ngIf="emailForm.controls['email'].errors?.['required']">Email is required.</div>
            <div *ngIf="emailForm.controls['email'].errors?.['email']">Invalid email format.</div>
        </ng-container>
    </div>

    <app-general-modal-footer
        [cancelText]="type === 'follow' ?  null : 'SKIP NOW'"
        [confirmDisabled]="emailForm.invalid"
        [confirming]="updating"
        (cancel)="cancel()"
        (ok)="confirm()"
    ></app-general-modal-footer>
</div>
