import dayjs from 'dayjs';
import UTC from 'dayjs/plugin/utc';
import { JSEncrypt } from 'jsencrypt';
dayjs.extend(UTC);

export function popupWindow(url: string, title = 'SocialScan', needFocus = true, w = 500, h = 600) {
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

    const width = window.innerWidth
        ? window.innerWidth
        : window.document.documentElement.clientWidth
        ? window.document.documentElement.clientWidth
        : screen.width;
    const height = window.innerHeight
        ? window.innerHeight
        : window.document.documentElement.clientHeight
        ? window.document.documentElement.clientHeight
        : screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft;
    const top = (height - h) / 2 / systemZoom + dualScreenTop;

    const newWindow = window.open(url, title, `scrollbars=yes,width=${w / systemZoom}, height=${h / systemZoom}, top=${top}, left=${left}`);
    if (needFocus) {
        try {
            newWindow.focus();
        } catch (err) {
            console.error(err);
        }
    }

    return newWindow;
}

export function isGetAgeDone(res: string[], level = 2) {
    return res.length == level;
}

export function getUtcDate(date: string, format = 'YYYY-MM-DD HH:mm:ss') {
    return dayjs(date).utc().format(format);
}
export function getName(duration: number, type: 'day' | 'hour' | 'minute' | 'second', isShort = false) {
    const shortNameMap = {
        day: 'day',
        hour: 'hr',
        minute: 'min',
        second: 'sec',
    };

    if (duration > 1) {
        return isShort ? shortNameMap[type] + 's' : type + 's';
    } else {
        return isShort ? shortNameMap[type] : type;
    }
}

export function getAge(
    date: string,
    configPassed?: {
        isShort?: boolean;
        level?: 1 | 2 | 3;
    }
) {
    const res = [];
    const now = dayjs();
    const defaultConfig = { isShort: true, level: 1 };
    const config = { ...defaultConfig, ...(configPassed || {}) };

    const durationDay = now.diff(date, 'day');
    if (durationDay !== 0) {
        res.push(Math.abs(durationDay) + ` ${getName(Math.abs(durationDay), 'day', config.isShort)}`);
    }
    if (isGetAgeDone(res, config.level)) {
        return res.join(' ') + (durationDay > 0 ? ' ago' : ' left');
    }

    const durationHours = now.diff(date, 'hour') % 24;
    if (durationHours !== 0) {
        res.push(Math.abs(durationHours) + ` ${getName(Math.abs(durationHours), 'hour', config.isShort)}`);
    }
    if (isGetAgeDone(res, config.level)) {
        return res.join(' ') + (durationHours > 0 ? ' ago' : ' left');
    }

    const durationMinutes = now.diff(date, 'minute') % 60;
    if (durationMinutes !== 0) {
        res.push(Math.abs(durationMinutes) + ` ${getName(Math.abs(durationMinutes), 'minute', config.isShort)}`);
    }
    if (isGetAgeDone(res, config.level)) {
        return res.join(' ') + (durationMinutes > 0 ? ' ago' : ' left');
    }

    const durationSeconds = now.diff(date, 'second') % 60;
    if (durationSeconds !== 0) {
        res.push(Math.abs(durationSeconds) + ` ${getName(Math.abs(durationSeconds), 'second', config.isShort)}`);
    }

    return res.join(' ') + (res.length ? (durationSeconds > 0 ? ' ago' : ' left') : '');
}

export function typeWriter(text: string, interval: number, updateCallback: (char: string, done?: boolean) => void): void {
    let i = 0;
    const typing = () => {
        if (i < text.length) {
            updateCallback(text.charAt(i));
            i++;
            setTimeout(typing, interval);
        } else {
            updateCallback('', true);
        }
    };
    typing();
}

export function copy(msg: string) {
    if (navigator.clipboard?.writeText) {
        return navigator.clipboard.writeText(msg);
    } else {
        const promiseResult = new Promise<boolean>((resolve, reject) => {
            try {
                const textarea = document.createElement('textarea');
                document.body.appendChild(textarea);
                textarea.value = msg;
                textarea.select();
                document.execCommand('Copy', false, undefined);
                document.body.removeChild(textarea);
                resolve(true);
            } catch (err) {
                console.error(err);
                reject();
            }
        });

        return promiseResult;
    }
}

const PUB_KEY = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAqUXC/J/V4BueF3te7D5o
lJTdyMkc1/UtPNLEZUyrB4MEDoiLuhfoLSVm8liCQAo+j5tFDEzo3SELdUqn3w1B
Y56iDRITvX1mbo5F3KRlW9wnxAvUgEzA5LkJ1Zptc+CiKHvnXy3zN0QgthIfH3L+
yXrK4/1YdH526SkdFiDX3lRIknnvaEMAjQ5hqJVNjwIKaXbfoQgwvBEwMHIOqgkb
0NU3TonbTCqHu88ypq0npiUG11Na39MU8KA4VG5TfZQZk5DYrfcHVm8dWRlV7a3Z
2xsjfT2T+NP0Mo9u3KtqHKOphObaMkos5VGwNh8MWeU+VW8C5zwngg7oIZFSxPKF
bwIDAQAB
-----END PUBLIC KEY-----
`;

export function encrypt(data: string) {
    const jsEncrypt = new JSEncrypt();
    jsEncrypt.setPublicKey(PUB_KEY);
    const res = jsEncrypt.encrypt(data);
    return btoa(res as string);
}

export function isValidAddress(address: string) {
    return address && address?.length === 42 && address?.startsWith('0x');
}
