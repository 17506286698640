import { AfterViewInit, Directive, ElementRef, HostListener, Input, OnChanges, SimpleChanges } from '@angular/core';
import { VisualMapComponentOption } from 'echarts';

import { BarChart, BarSeriesOption, LineChart, LineSeriesOption, PieChart, PieSeriesOption } from 'echarts/charts';
import {
    GridComponent,
    GridComponentOption,
    LegendComponent,
    TitleComponent,
    TitleComponentOption,
    ToolboxComponent,
    TooltipComponent,
    VisualMapComponent,
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer, SVGRenderer } from 'echarts/renderers';
echarts.use([
    GridComponent,
    LineChart,
    PieChart,
    BarChart,
    CanvasRenderer,
    TooltipComponent,
    TitleComponent,
    LegendComponent,
    ToolboxComponent,
    VisualMapComponent,
    SVGRenderer,
]);

export type EchartsOption = echarts.ComposeOption<
    GridComponentOption | LineSeriesOption | PieSeriesOption | BarSeriesOption | TitleComponentOption | VisualMapComponentOption
>;
export { echarts };

export const COLORS = [
    '#5854f4',
    '#4399ff',
    '#5ce4f8',
    '#9d80f9',
    '#b6f059',
    '#7a5cfe',
    '#de76b8',
    '#f4bce9',
    '#ece8bf',
    '#ffb872',
    '#ff8e27',
    '#7f88ab',
    '#aea0fa',
    '#41d975',
    '#4e13ff',
    '#c4a2eb',
    '#14d1c0',
    '#ef3965',
    '#ff7a84',
    '#8dfce0',
    '#1fc241',
    '#ffcf05',
    '#2dffbc',
];

@Directive({
    selector: '[appEcharts]',
})
export class EchartsDirective implements AfterViewInit, OnChanges {
    @Input() initialOption: EchartsOption;
    @Input() optionToBeUpdate: EchartsOption & { notMerge?: boolean };
    @Input() theme: 'light' | 'dark' = 'dark';

    private charts: echarts.EChartsType;
    private chartInitialized = false;

    constructor(private el: ElementRef) {}

    @HostListener('window:resize')
    resizeChart() {
        this.charts?.resize();
    }

    ngOnChanges(change: SimpleChanges) {
        if (!change['optionToBeUpdate']?.currentValue) return;
        const needMerge = !!this.optionToBeUpdate?.notMerge;
        delete this.optionToBeUpdate?.notMerge;
        const newOption = this.optionToBeUpdate;

        if (this.chartInitialized) {
            this.charts.setOption(newOption, needMerge);
        } else {
            const func = () => {
                if (this.charts) {
                    this.charts.setOption(newOption, needMerge);
                } else {
                    setTimeout(() => {
                        func();
                    }, 10);
                }
            };

            func();
        }
    }

    showLoading() {
        this.charts.showLoading();
    }

    hideLoading() {
        this.charts.hideLoading();
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.charts = echarts.init(this.el.nativeElement, this.theme, { renderer: 'svg' });
            this.charts.setOption(this.initialOption);
            this.chartInitialized = true;
        }, 10);
    }
}
