<div *ngIf="badges[0].type !== 'onchain'" class="text-center w-full min-h-[200px] flex flex-col justify-center items-center py-8">
    <div class="font-normal">
        <span *ngIf="badges.length > 1">Congrats! You've claimed those badges</span>
        <span *ngIf="badges.length === 1">Congrats! You've claimed this badge</span>
        <ng-container *ngIf="additionalInvitationCodes">
            , <span class="text-brand">{{ totalPoints }}</span> points and <span class="text-brand">{{additionalInvitationCodes}}</span> Invitation Codes
        </ng-container>
        <ng-container *ngIf="!additionalInvitationCodes"> and <span class="text-brand">{{ totalPoints }}</span> points </ng-container>
    </div>

    <div *ngIf="gottenConnectTwitterBadge && additionalInvitationCodes" class="text-center italic py-8">Invite friends to get Points Reward!</div>

    <div class="flex justify-center items-center gap-2.5 gap-x-5 mt-5 flex-wrap">
        <div *ngFor="let badge of badges">
            <img class="w-16 h-16 mx-auto" [src]="badge.icon_enabled" alt="" />
            <div class="mt-3 text-xs">{{ badge.name }}</div>
        </div>
    </div>
</div>

<div *ngIf="badges[0].type === 'onchain'" class="text-center w-full min-h-[200px] flex flex-col justify-center items-center py-8">
    <div class="font-normal">Congrats! You've claimed this badge</div>

    <div class="flex justify-center items-center gap-2.5 gap-x-5 mt-5 flex-wrap">
        <img class="w-24 h-24 mx-auto" [src]="badges[0].icon_enabled" alt="" />
    </div>

    <div *ngIf="badges[0].id === 'linea_data_scanner'" class="mt-5">Go to 'My Badge' and follow 5 people who also own this badge to win 'Linea Gang'.</div>
    <div *ngIf="badges[0].id === 'ultiverse_ai_day'" class="mt-5">Go to 'My Badge' and follow 5 people who also own this badge to win 'Ultiverse Gang'.</div>
</div>

<div class="flex mt-2.5 justify-center items-center gap-x-2.5 w-full max-w-[320px] mx-auto">
    <button *ngIf="badges[0].id === 'linea_data_scanner'" nz-button nzShape="round" class="flex-1" (click)="goLineaDataScanner()">Linea Data Scanner</button>
    <button *ngIf="badges[0].id === 'ultiverse_ai_day'" nz-button nzShape="round" class="flex-1" (click)="goUltiveserAIDay()">Ultiverse AI $Day</button>
    <button nz-button nzType="primary" class="flex-1" (click)="shareToTwitter()">Share to X</button>
</div>
