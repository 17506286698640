import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BaseInfo } from '../shared/types/common.type';
import { RequestService } from './request.service';

export interface Notification extends BaseInfo {
    id: number;
    user_id: string;
    type: 'system' | 'new_referral_code' | 'token_subscription' | 'project_subscription' | string;
    title: string;
    message: string;
    is_read: boolean;
    logo?: string;
}

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    messages$ = new BehaviorSubject<Notification[]>(null);

    get messages() {
        return this.messages$.getValue();
    }

    constructor(private requestService: RequestService) {}

    getNotifications() {
        return this.requestService
            .sendRequest<{ data: Notification[]; total: number; unread: boolean }>({
                method: 'GET',
                url: '/v1/socialscan/user/notifications',
            })
            .then(data => this.messages$.next(data.data));
    }

    markMessageAsRead(id: number) {
        return this.requestService.sendRequest({
            method: 'POST',
            url: '/v1/socialscan/user/notification/read',
            data: { id },
        });
    }
}
