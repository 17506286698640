export enum LOCAL_STORAGE_KEY_ENUM {
    CURRENT_WALLET = 'current_wallet',
    TOKEN = 'token',
    EMAIL_MODAL_SHOWN = 'email_modal_shown',
    TWITTER_USER_INFO = 'twitterUserInfo', // 类似near的钱包都是当前页面跳转，所以service中信息会丢失，必须用web storage 的方式记下来
    DISCORD_USER_INFO = 'discordUserInfo', // 类似near的钱包都是当前页面跳转，所以service中信息会丢失，必须用web storage 的方式记下来
    TELEGRAM_USER_INFO = 'telegramUserInfo', // 类似near的钱包都是当前页面跳转，所以service中信息会丢失，必须用web storage 的方式记下来
    CAMPAIGN_ID = 'campaignId', // 目前用在GA Event. app内置浏览器中用户在社交媒体授权后是页面跳转，所以需要在跳转前记录campaignId
    SOCIAL_MEDIA_TYPE = 'socialMediaType', // 社交媒体授权跳转前的社交媒体类型
    SOCIAL_MEDIA_AUTH = 'social_media_auth', // 社交媒体授权跳转前的社交媒体类型
}
