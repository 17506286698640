import { Component } from '@angular/core';
import { CommonService } from '@src/app/services/common.service';
import { WalletStatusService } from '@src/app/services/wallet-status.service';

@Component({
    selector: 'app-global-footer',
    templateUrl: './global-footer.component.html',
    styleUrls: ['./global-footer.component.less'],
})
export class GlobalFooterComponent {
    year = new Date().getFullYear();

    constructor(public commonService: CommonService, public walletStatusService: WalletStatusService) {}
}
