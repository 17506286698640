import { Component, Input, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '@src/app/services/common.service';
import { environment } from '@src/environments/environment';
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { BadgeApiInfo } from '../../types/badge.type';
import { AllBadgesComponent } from '../all-badges/all-badges.component';

@Component({
    selector: 'app-badge-info',
    templateUrl: './badge-info.component.html',
    styleUrls: ['./badge-info.component.less'],
})
export class BadgeInfoComponent {
    @Input() badges: BadgeApiInfo[];
    @Input() additionalPoints = 0;
    @Input() additionalInvitationCodes = 0;

    get totalPoints() {
        return this.additionalPoints + this.badges?.reduce((acc, badge) => acc + badge.points, 0) || 0;
    }

    get gottenConnectTwitterBadge() {
        return this.badges?.some(badge => badge.id === 'connect_twitter');
    }

    constructor(
        private commonService: CommonService,
        private drawerService: NzDrawerService,
        private router: Router,
        @Optional() private drawerRef: NzDrawerRef,
        @Optional() private modalRef: NzModalRef
    ) {}

    shareToTwitter() {
        const badgeTwitterName = this.badges[0].name
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join('');

        let badgeType = 'badge';
        if (this.badges[0].section === 'Onchain') {
            badgeType = 'NFT+badge';
        }

        window.open(
            `https://twitter.com/intent/tweet?text=I+just+claimed+an+awesome+%23${badgeTwitterName}+${badgeType}+on+socialscan.io!+Join+me+in+the+fun+now!+%F0%9F%8C%9E+%23ScanAnything`
        );
    }

    showAllBadges() {
        if (this.commonService.isMobile) {
            this.drawerService.create({
                nzContent: AllBadgesComponent,
                nzTitle: 'All badges',
                nzFooter: null,
                nzPlacement: 'bottom',
                nzHeight: 'auto',
                nzClosable: true,
            });
        } else {
            this.router.navigateByUrl('/my-profile');
        }

        this.close();
    }

    close() {
        this.modalRef?.triggerOk();
        this.drawerRef?.close();
    }

    goLineaDataScanner() {
        if (environment.production) {
            this.router.navigate(['/community/contract/linea/0x780de722234532f7d61ca3d147574f44a85c4244']);
        } else {
            this.router.navigate(['/community/contract/linea-testnet/0x4ff8f1cf513f0f4b69c946dcf93d82a9f8cc6f56']);
        }
        this.close();
    }

    goUltiveserAIDay() {
        this.router.navigate(['/community/contract/polygon/0x1c682a755dc820a0e63de7b5b38c55e311cf15e2']);
        this.close();
    }
}
