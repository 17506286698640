import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Transaction } from '../shared/types/transaction.type';

@Injectable({
    providedIn: 'root',
})
export class GlobalStatusService {
    followStatusChange$ = new BehaviorSubject<{ status: boolean; address?: string }>(null);

    txnPinChange$ = new BehaviorSubject<{ pinned: boolean; txn: Transaction }>(null);

    tokenPinChange$ = new BehaviorSubject<{ pinned: boolean; contractAddress: string; tokenId: number | string }>(null);

    updateFollowStatus(status: boolean, address: string) {
        this.followStatusChange$.next({ status, address });
    }
}
