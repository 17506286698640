import { Pipe, PipeTransform } from '@angular/core';
import { CommonService } from '@src/app/services/common.service';

@Pipe({
    name: 'kmb2',
})
export class KMBPipe implements PipeTransform {
    constructor(private commonService: CommonService) {}

    transform(value: number | string, digitsCount: number, skipK = false): string {
        return this.commonService.formatNumberToKMB(Number(value), digitsCount, skipK);
    }
}
