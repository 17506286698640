<svg viewBox="0 0 176 198" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- 插入图片并应用剪切路径, 剪切路径定义在index.html中 -->
    <image *ngIf="imgUrl" [attr.href]="imgUrl" [attr.width]="size" [attr.height]="size*1.125" clip-path="url(#hexagonClip)"></image>

    <path
        *ngIf="!borderLess"
        d="M152.22,51.34l-55-31.66a16.46,16.46,0,0,0-16.59.09L24.36,53a16.47,16.47,0,0,0-8.1,14l-.71,65.2a16.41,16.41,0,0,0,8.23,14.44l55,31.66a16.46,16.46,0,0,0,16.59-.09L151.63,145a16.46,16.46,0,0,0,8.1-14l.72-65.2A16.42,16.42,0,0,0,152.22,51.34Z"
        [attr.stroke]="borderColor"
        fill="transparent"
        [attr.stroke-width]="strokeWidth"
    ></path>
    <path
        *ngIf="fillColor"
        d="M152.22,51.34l-55-31.66a16.46,16.46,0,0,0-16.59.09L24.36,53a16.47,16.47,0,0,0-8.1,14l-.71,65.2a16.41,16.41,0,0,0,8.23,14.44l55,31.66a16.46,16.46,0,0,0,16.59-.09L151.63,145a16.46,16.46,0,0,0,8.1-14l.72-65.2A16.42,16.42,0,0,0,152.22,51.34Z"
        stroke="transparent"
        [attr.fill]="fillColor"
        stroke-width="2"
    ></path>
</svg>
