<div
    class="w-full px-4 pb-2.5 pt-6"
    [ngClass]="{
        'pt-6 bg-dropdown':walletStatusService.userProfile && walletStatusService.signUpCompleted,
        'pt-2.5':!walletStatusService.userProfile || !walletStatusService.signUpCompleted
    }"
>
    <ng-container *ngIf="walletStatusService.userProfile && walletStatusService.signUpCompleted">
        <app-hexagon-img [imgUrl]="walletStatusService.userProfileImg" class="block w-20"></app-hexagon-img>

        <div class="px-2.5">
            <div class="text-base font-normal truncate my-2 flex-1">{{walletStatusService.userProfileName|omit:6:6}}</div>

            <div *ngIf="myBadges?.length" class="flex-1 flex justify-start items-center gap-1 mb-2.5">
                <img
                    *ngFor="let item of myBadges | slice:0:4"
                    [src]="item.icon_enabled || '/assets/imgs/badges/follower-1000.png'"
                    [alt]="item.name"
                    class="w-8 h-8"
                    [nz-tooltip]="item.name"
                />
            </div>
        </div>

        <div class="!py-2 cursor-pointer rounded-md !px-2 hover:bg-white hover:bg-opacity-6" (click)="go('/my-profile')">
            <img class="mr-2.5 align-middle inline-block w-5" src="/assets/imgs/icon/user.png" />
            <span>Profile</span>
        </div>

        <div class="!py-2 cursor-pointer rounded-md !px-2 hover:bg-white hover:bg-opacity-6" (click)="goPoints()">
            <img class="mr-2.5 align-middle inline-block w-5" src="/assets/imgs/icon/points.png" />
            <span>Points</span>
        </div>

        <div class="!py-2 cursor-pointer rounded-md !px-2 hover:bg-white hover:bg-opacity-6" (click)="goBadges()">
            <img src="/assets/imgs/icon/badge.png" class="inline-block align-middle w-5 mr-2.5" alt="" />
            <span>Badges</span>
        </div>

        <div
            class="!py-2 rounded-md !px-2 hover:bg-white hover:bg-opacity-6"
            (click)="showMessageWidget()"
            [ngClass]="{
                'opacity-50 cursor-not-allowed': !messageEnabled,
                'cursor-pointer': messageEnabled
            }"
        >
            <img class="mr-2.5 align-middle inline-block w-5" src="/assets/imgs/icon/message.png" />
            <span>Message</span>
            <span *ngIf="widgetUnReadCountVal" class="chat_widget_btn_bage">{{ widgetUnReadCountVal }}</span>
        </div>

        <div class="!py-2 cursor-pointer rounded-md !px-2 hover:bg-white hover:bg-opacity-6" (click)="go('/my-profile/settings')">
            <img class="mr-2.5 align-middle inline-block w-5" src="/assets/imgs/icon/settings.png" />
            <span>Settings</span>
        </div>
    </ng-container>

    <div class="!py-2 cursor-pointer rounded-md !px-2 hover:bg-white hover:bg-opacity-6" (click)="logout()">
        <img class="mr-2.5 align-middle inline-block w-5" src="/assets/antd/logout.svg" />
        <span class="text-[#E02A76]">Logout</span>
    </div>
</div>

<!-- My Points -->
<section *ngIf="myPointsPageVisible" [@enter] [@leave] class="container p-4 fixed top-0 h-full w-full left-0 z-50 bg-white">
    <h2 class="text-xl font-bold flex justify-between items-center gap-x-2.5 mb-5 cursor-pointer w-full">
        <div class="inline-flex justify-start items-center gap-x-2.5" (click)="myPointsPageVisible = false">
            <img src="/assets/imgs/icon/left-arrow.png" alt="" class="w-6" />
            <span> Points Records </span>
        </div>
    </h2>

    <div style="height: calc(100% - 92px)" class="flex flex-col justify-start items-start">
        <div class="border border-line border-opacity-30 rounded-lg p-5 overflow-y-auto flex-1 w-full">
            <ng-container *ngFor="let item of myProfileService.pointsHistory;let last = last">
                <div class="flex justify-between items-center w-full">
                    <div>
                        <div class="text-base font-bold mb-1">
                            <ng-container *ngIf="item.task_type === 'repeat_earn_badge'">Get </ng-container>

                            <span> {{item.reference_name || PointTaskNameMap[item.task_type]}}</span>
                        </div>
                        <div class="text-sub-text text-xs">{{item.updated_at| date:'HH:mm MMM dd, yyyy'}}</div>
                    </div>
                    <div class="text-right">
                        <div class="text-base font-bold text-w3w">+{{item.points}}</div>
                        <div *ngIf="!item.is_finalized" class="text-sub-text text-xs mt-1">Pending</div>
                    </div>
                </div>

                <div *ngIf="!last" class="bg-[#E6EBEF] h-[1px] my-5"></div>
            </ng-container>
        </div>
    </div>
</section>

<!-- My Badges -->
<section *ngIf="myBadgePageVisible" [@enter] [@leave] class="container p-4 fixed top-0 h-full w-full left-0 z-50 bg-[#F9FAFB]">
    <h2 class="text-xl font-bold flex justify-between items-center gap-x-2.5 mb-5 cursor-pointer w-full">
        <div class="inline-flex justify-start items-center gap-x-2.5" (click)="myBadgePageVisible = false">
            <img src="/assets/imgs/icon/left-arrow.png" alt="" class="w-6" />
            <span> My Badges ({{myBadges?.length}}) </span>
        </div>
    </h2>

    <div class="bg-white rounded-lg py-5 px-3">
        <app-all-badges></app-all-badges>
    </div>
</section>
