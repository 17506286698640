<div class="px-1 pb-5">
    <div *ngFor="let section of myProfileService.badgesBySection;let first = first;">
        <div *ngIf="!first" class="gradient-line h-[1px] my-6"></div>

        <div class="mb-5 font-normal relative w-full flex-between-center">
            <div>
                <span>{{section.section}}</span>
                <span *ngIf="section.section === 'DAY Mining' && !isMiningCampaignEnded" class="new ml-2.5">New</span>
            </div>

            <div *ngIf="section.section === 'DAY Mining' && !isMiningCampaignEnded" class="text-right flex-end-center text-xs text-white text-opacity-50">
                <span nz-icon nzType="hourglass" nzTheme="outline"></span>
                <span>{{restDays}}</span>
            </div>
        </div>
        <div class="grid grid-cols-3 md:grid-cols-4 gap-x-1 gap-y-4 mt-2.5">
            <app-badge *ngFor="let item of section.badges" [badge]="item"></app-badge>
        </div>
    </div>
</div>
