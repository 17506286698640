<div class="p-2">
    <div class="flex flex-col justify-start items-stretch gap-y-2.5 font-bold w-full">
        <div *ngIf="tgService.isOnlyTg" class="px-4 py-2 rounded-xl text-sm text-[#2990FFE5] bg-[#2990FF26] font-normal">
            Please connect a new wallet. Ensure this wallet has not been previously connected or binded with SocialScan. <br />
            Otherwise, your current account information will be updated with that of the old wallet.
        </div>

        <div
            *ngFor="let wallet of wallets"
            class="w-full bg-white bg-opacity-6 h-[64px] py-1.5 px-4 rounded-2xl flex-between-center gap-x-2.5 cursor-pointer text-base transition-shadow hover:shadow-md"
            (click)="connect(wallet.name)"
        >
            <div class="gap-x-2.5 flex-start-center">
                <img class="w-10 h-10 object-contain" [src]="wallet.img" [alt]="wallet.displayName" />
                <div class="font-bold" style="line-height: 1.2">{{wallet.displayName}}</div>
            </div>

            <span *ngIf="connectingWallet === wallet.name" nz-icon nzType="loading" nzTheme="outline"></span>
            <img *ngIf="connectingWallet !== wallet.name" src="/assets/imgs/v2/long-right-arrow-orange.png" class="w-6 h-6 object-contain" />
        </div>
    </div>
</div>
