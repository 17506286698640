<!-- Desktop -->
<header *ngIf="!commonService.isMobile" class="bg-opacity-10 relative">
    <!-- <div class="general-bg"></div> -->
    <div class="flex-between-center container py-5">
        <img src="/assets/imgs/v3/socialscan-v3.png" class="h-10 cursor-pointer" alt="SocialScan" [routerLink]="['/']" />

        <div class="flex-end-center gap-x-5 flex-1">
            <nav *ngIf="!desktopSearching" class="flex-1 flex justify-start items-center navigation-links pl-10">
                <div class="flex-start-center gap-x-10">
                    <ng-container *ngIf="walletStatusService.isWalletConnected && walletStatusService.registrationCompleted">
                        <button
                            [nzType]="selectedTab === 'Home' ? 'primary' : 'text'"
                            [ngClass]="selectedTab === 'Home' ? '' : '!transition-none'"
                            nz-button
                            nzType="primary"
                            [routerLink]="['/my-profile']"
                        >
                            Home
                        </button>

                        <button
                            class="cursor-pointer p-1 select-none italic font-normal h-10"
                            [ngClass]="selectedTab === 'Agents' ? '' : '!transition-none'"
                            nz-button
                            [nzType]="selectedTab === 'Agents' ? 'primary' : 'text'"
                            [routerLink]="['/hemera','agents']"
                            [@tada]="animateState"
                        >
                            <span [ngClass]="selectedTab === 'Agents' ? '' : 'colorful-text'">AI Agent Hub</span>
                        </button>

                        <button
                            class="cursor-pointer p-1 select-none italic font-normal h-10"
                            [ngClass]="selectedTab === 'DayMining' ? '' : '!transition-none'"
                            nz-button
                            [nzType]="selectedTab === 'DayMining' ? 'primary' : 'text'"
                            [routerLink]="['/hemera','agent','mining-days']"
                        >
                            <img *ngIf="selectedTab !== 'DayMining'" src="/assets/imgs/icon/colorful-hoe.png" class="w-5 mr-2" />
                            <img *ngIf="selectedTab === 'DayMining'" src="/assets/imgs/icon/hoe-white.png" class="w-5 mr-2" />

                            <span [ngClass]="selectedTab === 'DayMining' ? '' : 'colorful-text'"> $DAY Mining </span>
                        </button>
                    </ng-container>

                    <button
                        class="cursor-pointer p-1 select-none italic font-normal h-10"
                        [ngClass]="selectedTab === 'fbtc-dashboard' ? '' : '!transition-none'"
                        nz-button
                        [nzType]="selectedTab === 'fbtc-dashboard' ? 'primary' : 'text'"
                        [routerLink]="['/coin-dashboard/fbtc']"
                    >
                        <img *ngIf="selectedTab !== 'fbtc-dashboard'" src="/assets/imgs/icon/colorful-fbtc.png" class="w-5 mr-2" />
                        <img *ngIf="selectedTab === 'fbtc-dashboard'" src="/assets/imgs/icon/fbtc-white.png" class="w-5 mr-2" />
                        <span [ngClass]="selectedTab === 'fbtc-dashboard' ? '' : 'colorful-text'"> FBTC </span>
                    </button>
                </div>
            </nav>

            <div class="flex justify-start items-center gap-x-5">
                <div *ngIf="walletStatusService.isWalletConnected && walletStatusService.registrationCompleted" (click)="$event.stopPropagation()">
                    <app-search-input *ngIf="desktopSearching" mode="desktop" class="block w-[340px]" (goSearchedItem)="goSearchedItem()"></app-search-input>

                    <div *ngIf="!desktopSearching" class="flex-start-center gap-x-5">
                        <div
                            class="ripple-container relative w-10 text-xl h-10 rounded-full flex justify-center items-center cursor-pointer hover:bg-opacity-30"
                            [ngClass]="{'bg-black': !searchAnimate,'bg-opacity-20 bg-[#ff952a]': searchAnimate}"
                            (click)="showDesktopSearching()"
                            (mouseleave)="leaveSearchBtn()"
                            (mouseenter)="enterSearchBtn()"
                        >
                            <span nz-icon nzType="antd:search"></span>
                            <ng-container *ngIf="searchAnimate">
                                <div class="ripple-1 animate-ripple-inner pointer-events-none"></div>
                                <div class="ripple-2 animate-ripple-outer pointer-events-none"></div>
                            </ng-container>

                            <div *ngIf="searchTooltipVisible" class="absolute left-1/2 -translate-x-1/2 top-[44px] backdrop-blur">
                                <img src="/assets/imgs/search-tooltip-bg.svg" class="min-w-[220px] h-auto" alt="" />
                                <div class="w-full px-4 py-1 text-center text-xs absolute h-full left-0 top-0 flex-center-center">
                                    <span class="colorful-text">You can explore more portfolio information here.</span>
                                </div>
                            </div>
                        </div>

                        <div
                            class="w-10 text-xl h-10 rounded-full flex justify-center items-center cursor-pointer bg-black hover:bg-opacity-50 relative"
                            nz-dropdown
                            [nzDropdownMenu]="notificationMenu"
                            [nzTrigger]="'click'"
                        >
                            <span nz-icon nzType="antd:bell"></span>

                            <div *ngIf="hasUnreadNotifications" class="absolute w-2 h-2 rounded-full bg-[#FA4E3E] right-2.5 top-2.5"></div>
                        </div>
                    </div>
                </div>

                <button *ngIf="!isWalletConnected" nz-button nzType="primary" nzSize="small" class="!px-5" [routerLink]="['/','home']">Launch App</button>
                <div
                    *ngIf="isWalletConnected"
                    class="cursor-pointer text-base rounded-full px-3 flex justify-start items-center gap-x-2 h-10 bg-black"
                    nz-dropdown
                    [nzDropdownMenu]="profileMenu"
                    [nzTrigger]="'click'"
                >
                    <app-hexagon-img class="w-[28px]" [imgUrl]="walletStatusService.userProfileImg"></app-hexagon-img>
                    <span> {{ (commonService.getAddrName(userProfile) || walletStatusService.walletAddress)| omit:4:4 }} </span>
                </div>

                <nz-dropdown-menu #profileMenu="nzDropdownMenu">
                    <ul nz-menu class="!px-0 !py-0 !rounded-2xl w-[280px] overflow-hidden">
                        <app-profile-dropdown nz-menu-item></app-profile-dropdown>
                    </ul>
                </nz-dropdown-menu>
            </div>
        </div>
    </div>
</header>

<!-- Mobile -->
<header *ngIf="commonService.isMobile" class="flex-between-center relative container p-4">
    <ng-container *ngIf="!isInTg">
        <div class="flex-between-center gap-x-5 h-[44px] w-full rounded-3xl bg-black p-1.5">
            <div class="w-8 h-8 rounded-full flex-center-center bg-[#0E0E0E]">
                <img
                    src="/assets/imgs/icon/menu.png"
                    class="w-5 h-5"
                    alt=""
                    (click)="myProfileMobileNavVisible = !myProfileMobileNavVisible;$event.stopPropagation()"
                />
            </div>

            <div class="flex justify-end items-center gap-x-2">
                <app-search-input mode="mobile" mobileType="myProfileMobile"></app-search-input>

                <div
                    *ngIf="walletStatusService.isWalletConnected && walletStatusService.registrationCompleted"
                    class="w-8 text-xl h-8 rounded-full flex justify-center items-center cursor-pointer bg-[#0E0E0E] relative"
                    nz-dropdown
                    [nzDropdownMenu]="notificationMenu"
                    [nzTrigger]="'click'"
                >
                    <span nz-icon nzType="antd:bell"></span>

                    <div *ngIf="hasUnreadNotifications" class="absolute w-2 h-2 rounded-full bg-[#FA4E3E] right-2.5 top-2.5"></div>
                </div>
            </div>
        </div>

        <!-- My Profile Mobile Navigation -->
        <div
            class="my-profile-mobile-navigation-box z-[9999] shadow rounded-bl-md rounded-br-md"
            (click)="$event.stopPropagation()"
            [style.maxHeight]="myProfileMobileNavVisible ? '204px' : '0'"
        >
            <div class="mobile-navigation-links">
                <ul nz-menu nzMode="inline" [nzSelectable]="false">
                    <li nz-menu-item (click)="login();myProfileMobileNavVisible = false">Home</li>
                    <li nz-menu-item [routerLink]="['/']" (click)="myProfileMobileNavVisible = false">SocialScan</li>
                    <li nz-menu-item [routerLink]="['/','hemera','agents']" (click)="myProfileMobileNavVisible = false">Hemera AI Agents</li>
                    <li nz-menu-item [routerLink]="['/','coin-dashboard','fbtc']" (click)="myProfileMobileNavVisible = false">FBTC</li>
                </ul>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="isInTg">
        <div class="flex-between-center gap-x-5 h-10 w-full">
            <img src="/assets/imgs/connect-wallet.png" class="w-auto h-10" alt="" />

            <div class="flex justify-end items-center gap-x-2">
                <app-search-input mode="mobile" class="search-input-box" mobileType="myProfileMobile"></app-search-input>

                <div
                    *ngIf="walletStatusService.isWalletConnected && walletStatusService.registrationCompleted"
                    class="w-10 text-xl h-10 rounded-full flex justify-center items-center cursor-pointer bg-[#0E0E0E] relative"
                    nz-dropdown
                    [nzDropdownMenu]="notificationMenu"
                    [nzTrigger]="'click'"
                >
                    <span nz-icon nzType="antd:bell"></span>

                    <div *ngIf="hasUnreadNotifications" class="absolute w-2 h-2 rounded-full bg-[#FA4E3E] right-2.5 top-2.5"></div>
                </div>
            </div>
        </div>
    </ng-container>
</header>

<nz-dropdown-menu #notificationMenu="nzDropdownMenu">
    <ul nz-menu class="!p-6 !rounded-3xl w-[80vw] md:w-[520px] overflow-hidden bg-[#0E0E0E] border border-white border-opacity-10">
        <div class="h-8 flex-between-center">
            <div class="text-base md:text-xl font-normal">Notifications</div>
            <button *ngIf="hasUnreadNotifications" nz-button nzType="text" nzSize="small" class="" (click)="markAllAsRead()">
                <span nz-icon nzType="antd:check-all" class="text-2xl"></span>
                <span>Mark all as read</span>
            </button>
        </div>

        <div *ngIf="notifications" class="max-h-[354px] overflow-y-auto mt-4 small-scrollbar">
            <ng-container *ngFor="let item of notifications;let last = last">
                <div
                    class="flex justify-start items-start gap-x-2 p-1 transition-all rounded-md hover:bg-white hover:bg-opacity-6 cursor-pointer"
                    (click)="markAsRead(item)"
                >
                    <nz-badge
                        style="margin-top: 8px"
                        [nzDot]="!item.is_read"
                        [nzStyle]="{
                            'backgroundColor':'#FA4E3E',
                            'width':'8px',
                            'height':'8px',
                        }"
                    >
                        <div
                            class="p-1.5 rounded-lg"
                            style="
                                background: linear-gradient(77.22deg, rgba(23, 182, 248, 0.1) 0%, rgba(23, 182, 248, 0.1) 0.01%, rgba(53, 208, 205, 0.1) 100%);
                            "
                        >
                            <img
                                *ngIf="item.type === 'token_subscription'"
                                [src]="item.logo || '/assets/imgs/icon/token-notification.png'"
                                alt=""
                                class="w-5 h-5"
                            />
                            <img
                                *ngIf="item.type === 'project_subscription'"
                                [src]="item.logo || '/assets/imgs/icon/project-notification.png'"
                                alt=""
                                class="w-5 h-5"
                            />
                            <img
                                *ngIf="item.type !== 'token_subscription' && item.type !== 'project_subscription'"
                                src="/assets/imgs/v3/system.png"
                                class="w-5 h-5"
                            />
                        </div>
                    </nz-badge>

                    <div class="flex-1 min-w-0 whitespace-break-spaces">
                        <div class="font-normal text-sm">{{item.title}}</div>

                        <div class="mt-1 text-sub-text text-xs">{{item.created_at | date:'HH:mm MMM dd, yyyy'}}</div>
                    </div>
                </div>

                <div *ngIf="!last" class="w-full gradient-line h-[1px] my-3"></div>
            </ng-container>

            <div *ngIf="notifications.length === 0" class="flex-center-center min-h-[200px] flex-col">
                <img src="/assets/imgs/no-data/no-notification.png" class="w-12" />
                <div class="mt-2 text-sub-text text-sm text-center">No notification</div>
            </div>
        </div>

        <nz-skeleton *ngIf="!notifications" [nzActive]="true"></nz-skeleton>
    </ul>
</nz-dropdown-menu>
