import { Injectable } from '@angular/core';
import { RequestService } from '@src/app/services/request.service';
import { ChainEnum } from '@src/app/shared/types/wallet-chain.type';

export interface AddressItem {
    wallet_address: string;
    ens_name: string;
    type: string;
    is_following: boolean;
    is_follower: boolean;
    allow_follower: boolean;
    display_name: string;
    profile_image: string;
    followers_count: number;
    followings_count: number;
}

export interface TxnItem {
    transaction_hash: string;
    chain: ChainEnum;
    type: string;
}

export interface CommunityItem {
    community_key: string;
    community_logo: string;
    name: string;
    type: string;
    contract_list: any[];
}

export interface ContractItem {
    chain: ChainEnum;
    contract_name: string;
    contract_address: string;
    contract_image_url: string;
    type: string;
}

@Injectable()
export class SearchInputService {
    constructor(private requestService: RequestService) {}

    search(searchVal: string) {
        return this.requestService.sendRequest$<any[]>({
            method: 'GET',
            url: `/v1/socialscan/inscription/search?q=${searchVal}`,
        });
    }

    searchV2(searchVal: string) {
        return this.requestService.sendRequest$<any[]>({
            method: 'GET',
            url: `/v1/socialscan/search_v2?q=${searchVal}`,
        });
    }
}
