import { Component, ElementRef, Input, Optional, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MyProfileService } from '@modules/my-profile/my-profile.service';
import { CommonService } from '@src/app/services/common.service';
import { WalletStatusService } from '@src/app/services/wallet-status.service';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
    selector: 'app-email-popup',
    templateUrl: './email-popup.component.html',
    styleUrls: ['./email-popup.component.less'],
    providers: [MyProfileService],
})
export class EmailPopupComponent {
    @ViewChild('emailInput') emailInput: ElementRef<HTMLInputElement>;
    @Input() type: 'first' | 'follow' = 'first';

    emailForm: FormGroup;

    updating = false;

    constructor(
        public commonService: CommonService,
        private myProfileService: MyProfileService,
        private walletStatusService: WalletStatusService,
        @Optional() private modalRef: NzModalRef,
        @Optional() private drawerRef: NzDrawerRef
    ) {
        this.emailForm = new FormGroup({
            email: new FormControl('', [Validators.required, Validators.email]),
        });
    }

    cancel() {
        this.modalRef?.close();
        this.drawerRef?.close();
    }

    confirm() {
        this.updating = true;
        this.myProfileService
            .updateUserProfile({ email: this.emailForm.get('email').value })
            .then(() => {
                this.walletStatusService.userProfile.email = this.emailForm.get('email').value;
                this.walletStatusService.getWalletProfile();
                this.commonService.success('Successfully updated');
                this.cancel();
            })
            .catch(err => this.commonService.errorMessageByResponse(err))
            .finally(() => (this.updating = false));
    }
}
