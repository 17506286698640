import { inject, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@shared/guards/auth.guard';
import { ProfileGuard } from '@shared/guards/profile.guard';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        loadComponent: () => import('./standalone-components/landing-page/landing-page.component').then(m => m.LandingPageComponent),
    },
    {
        path: 'home',
        loadComponent: () => import('./standalone-components/signup-with-wallet/signup-with-wallet.component').then(c => c.SignupWithWalletComponent),
    },
    {
        path: 'invite',
        loadComponent: () => import('./standalone-components/invitation-code-page/invitation-code-page.component').then(c => c.InvitationCodePageComponent),
    },
    {
        path: 'referral',
        canActivate: [() => inject(AuthGuard).canActivate()],
        loadComponent: () => import('./standalone-components/referral/referral.component').then(c => c.ReferralComponent),
    },
    // {
    //     path: 'inscription-dashboard',
    //     loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
    //     canActivate: [() => inject(AuthGuard).canActivate()],
    // },
    {
        path: 'transaction',
        loadChildren: () => import('./modules/transaction/transaction.module').then(m => m.TransactionModule),
        canActivate: [() => inject(AuthGuard).canActivate()],
    },
    {
        path: 'address',
        loadChildren: () => import('./modules/address/address.module').then(m => m.AddressModule),
        canActivate: [() => inject(AuthGuard).canActivate()],
    },

    {
        path: 'my-profile',
        loadChildren: () => import('./modules/my-profile/my-profile.module').then(m => m.MyProfileModule),
        canActivateChild: [() => inject(AuthGuard).canActivate(), () => inject(ProfileGuard).canActivate()],
    },

    {
        path: 'my-subscriptions',
        loadComponent: () => import('./standalone-components/my-subscriptions/my-subscriptions.component').then(m => m.MySubscriptionsComponent),
        canActivateChild: [() => inject(AuthGuard).canActivate(), () => inject(ProfileGuard).canActivate()],
    },
    {
        path: 'token',
        loadChildren: () => import('./modules/token/token.module').then(m => m.TokenModule),
        canActivate: [() => inject(AuthGuard).canActivate()],
    },
    {
        path: 'policy',
        loadChildren: () => import('./modules/policy/policy.module').then(m => m.PolicyModule),
    },
    {
        path: 'about-us',
        loadComponent: () => import('./standalone-components/about-us/about-us.component').then(m => m.AboutUsComponent),
    },
    {
        path: 'blogs',
        loadChildren: () => import('./modules/blog/blog.module').then(m => m.BlogModule),
    },
    {
        path: 'inscription',
        loadChildren: () => import('./modules/inscription/inscription.module').then(m => m.InscriptionModule),
        canActivate: [() => inject(AuthGuard).canActivate()],
    },

    {
        path: 'dashboard',
        redirectTo: '/my-profile',
    },
    {
        path: 'task/interacted-address',
        loadComponent: () =>
            import('./modules/my-profile/recent-interact-address/recent-interact-address.component').then(c => c.RecentInteractAddressComponent),
        canActivate: [() => inject(AuthGuard).canActivate(), () => inject(ProfileGuard).canActivate()],
    },
    {
        path: 'task/:task_id',
        loadComponent: () => import('./modules/my-profile/task/task.component').then(c => c.TaskComponent),
        canActivate: [() => inject(AuthGuard).canActivate(), () => inject(ProfileGuard).canActivate()],
    },
    {
        path: 'community',
        loadChildren: () => import('./modules/community/community.module').then(m => m.CommunityModule),
        canActivate: [() => inject(AuthGuard).canActivate()],
    },
    {
        path: 'hemera',
        loadChildren: () => import('./modules/hemera/hemera.module').then(m => m.HemeraModule),
        // canActivate: [() => inject(AuthGuard).canActivate(), () => inject(ProfileGuard).canActivate()],
    },
    {
        path: '',
        loadChildren: () => import('./modules/public/public.module').then(m => m.PublicModule),
    },
    {
        path: '**',
        redirectTo: '/',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            initialNavigation: 'enabledBlocking',
        }),
    ],

    exports: [RouterModule],
})
export class AppRoutingModule {}
