import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { WalletStatusService } from '@src/app/services/wallet-status.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard {
    constructor(private walletStatusService: WalletStatusService, private router: Router) {}

    async canActivate() {
        if (!this.walletStatusService.isWalletConnected) {
            this.router.navigate(['/home'], { queryParamsHandling: 'merge' });
            return false;
        }

        if (!this.walletStatusService.tokenStr$.getValue()) {
            await this.walletStatusService.updateUserToken();
        }

        // If token expired, then disconnect wallet. And redirect to home page.
        if (this.walletStatusService.tokenExp < Date.now()) {
            this.walletStatusService.disconnect();
            this.router.navigate(['/home'], { queryParamsHandling: 'merge' });
            return false;
        }

        if (!this.walletStatusService.checkIfSignupCompleted()) {
            return this.router.navigate(['/invite'], { queryParamsHandling: 'merge' });
        }

        return true;
    }
}
