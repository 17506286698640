import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-general-modal-footer',
    templateUrl: './general-modal-footer.component.html',
    styleUrls: ['./general-modal-footer.component.less'],
})
export class GeneralModalFooterComponent {
    @Input() cancelText = 'CANCEL';
    @Input() confirmText = 'OK';
    @Input() confirming = false;
    @Input() confirmDisabled = false;

    @Output() ok = new EventEmitter();
    @Output() cancel = new EventEmitter();

    okClick() {
        this.ok.emit();
    }

    cancelClick() {
        this.cancel.emit();
    }
}
