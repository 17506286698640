import { DOCUMENT, Location } from '@angular/common';
import { Directive, HostListener, Inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { RouterChangeMonitorService } from '@src/app/services/router-change-monitor.service';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[backButton]',
})
export class BackButtonDirective {
    @Input() backLink: string | (string | number)[];

    constructor(
        private location: Location,
        private router: Router,
        private routeMonitorService: RouterChangeMonitorService,
        @Inject(DOCUMENT) private document: Document
    ) {}

    @HostListener('click')
    onClick() {
        if (this.routeMonitorService.previousPath) {
            this.location.back();
        } else if (this.backLink !== undefined) {
            if (typeof this.backLink === 'string') {
                this.router.navigate([this.backLink], { replaceUrl: true });
            } else {
                this.router.navigate(this.backLink, { replaceUrl: true });
            }
        } else {
            console.warn('BackButtonDirective: No previous path or backLink defined.');
            this.location.back();
        }
    }
}
