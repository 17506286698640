import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-hexagon-img',
    templateUrl: './hexagon-img.component.html',
    styles: [
        `
            :host {
                display: block;
            }
        `,
    ],
})
export class HexagonImgComponent {
    @Input() imgUrl: string;
    @Input() borderLess = false;
    @Input() borderColor = '#ffffff';
    @Input() size = 176;
    @Input() fillColor: string;
    @Input() strokeWidth = 2;
}
