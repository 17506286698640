import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, Optional, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { MyProfilePopupService } from '@src/app/modules/my-profile/my-profile-popup.service';
import { MyProfileService } from '@src/app/modules/my-profile/my-profile.service';
import { CommonService } from '@src/app/services/common.service';
import { FollowStatusService } from '@src/app/services/follow-status.service';
import { WalletStatusService } from '@src/app/services/wallet-status.service';
import { BadgeApiInfo } from '@src/app/shared/types/badge.type';
import { PointTask, PointTaskNameMap } from '@src/app/shared/types/point-tasks.type';
import { environment } from '@src/environments/environment';
import { fadeInOnEnterAnimation, slideInLeftOnEnterAnimation, slideOutLeftOnLeaveAnimation } from 'angular-animations';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { Subject, filter, takeUntil } from 'rxjs';

@Component({
    selector: 'app-profile-dropdown',
    templateUrl: './profile-dropdown.component.html',
    styleUrls: ['./profile-dropdown.component.less'],
    animations: [
        slideInLeftOnEnterAnimation({ anchor: 'enter', duration: 200 }),
        slideOutLeftOnLeaveAnimation({ anchor: 'leave', duration: 200 }),
        fadeInOnEnterAnimation({ anchor: 'fadeIn', duration: 200 }),
    ],
})
export class ProfileDropdownComponent implements OnInit, OnDestroy {
    PointTaskNameMap = PointTaskNameMap;
    destroy$ = new Subject();
    widgetUnReadCount = 0;

    myBadgePageVisible = false;
    myPointsPageVisible = false;

    myBadges: BadgeApiInfo[];

    get widgetUnReadCountVal(): string | number {
        return this.widgetUnReadCount > 99 ? '99+' : this.widgetUnReadCount;
    }
    get userName() {
        return this.walletStatusService.userProfile?.display_name || this.walletStatusService.walletAddress;
    }
    get messageEnabled() {
        return environment.enableMessage;
    }
    constructor(
        private followStatusService: FollowStatusService,
        public commonService: CommonService,
        public walletStatusService: WalletStatusService,
        public myProfileService: MyProfileService,
        private myProfilePop: MyProfilePopupService,
        private router: Router,
        @Inject(PLATFORM_ID) private platformId: any,
        @Optional() private drawerRef: NzDrawerRef
    ) {}

    ngOnInit() {
        this.router.events.pipe(takeUntil(this.destroy$)).subscribe(() => this.close());

        this.followStatusService.followStatusChange$
            .pipe(
                filter(item => !!item),
                filter(({ address }) => address.toLowerCase() !== this.walletStatusService.walletAddress?.toLowerCase()),
                takeUntil(this.destroy$)
            )
            .subscribe(({ status }) => (this.walletStatusService.userProfile.followings_count += status ? 1 : -1));

        if (isPlatformBrowser(this.platformId)) {
            setTimeout(() => {
                window?.['chatWidgetApi']?.on('unReadCount', (count: number) => {
                    this.widgetUnReadCount = count;
                });
            }, 100);
        }

        this.myProfileService.badges$
            .pipe(
                filter(item => !!item),
                takeUntil(this.destroy$)
            )
            .subscribe(badges => (this.myBadges = badges.filter(item => item.is_earned)));
    }

    ngOnDestroy() {
        this.destroy$.next(null);
        this.destroy$.complete();
    }

    logout() {
        this.walletStatusService.disconnect();
        this.drawerRef?.close();
    }

    go(link: string) {
        this.router.navigateByUrl(link);
        this.drawerRef?.close();
    }

    goPoints() {
        this.myProfilePop.showPointsHistory();
        this.drawerRef?.close();
    }

    goBadges() {
        if (this.commonService.isMobile) {
            this.myBadgePageVisible = true;
        } else {
            this.myProfilePop.showAllBadges();
        }
    }

    goTask(task: PointTask) {
        if (task === PointTask.ONE_TIME_CLAIM_BADGE) {
            this.myPointsPageVisible = false;
            this.goBadges();
        } else {
            this.myPointsPageVisible = false;
            this.drawerRef?.close();
        }
    }

    close() {
        this.drawerRef?.close();
    }

    showMessageWidget() {
        if (!environment.enableMessage) return;

        if (isPlatformBrowser(this.platformId)) {
            setTimeout(() => {
                window['chatWidgetApi'].showWidget(true);
            }, 10);
        }
        this.drawerRef?.close();
    }
}
