import { BaseInfo } from './common.type';

export enum PointTask {
    ONE_TIME_SIGNUP = 'one_time_signup',
    ONE_TIME_ADD_EMAIL = 'one_time_add_email',
    ONE_TIME_UPDATE_PROFILE = 'one_time_update_profile',
    ONE_TIME_VERIFY_CONTRACT = 'one_time_verify_contract',
    ONE_TIME_CLAIM_BADGE = 'one_time_claim_badge',

    DAILY_LOGIN = 'daily_login',
    DAILY_FOLLOW = 'daily_follow',
    DAILY_LIKE = 'daily_like',
    DAILY_CHAT = 'daily_chat',

    MONTHLY_LOGIN_7_DAYS = 'monthly_login_7_days',
    MONTHLY_LOGIN_14_DAYS = 'monthly_login_14_days',
    MONTHLY_LOGIN_28_DAYS = 'monthly_login_28_days',
    MONTHLY_COPY_10_CONTRACTS = 'monthly_copy_10_contracts',
    MONTHLY_COPY_30_CONTRACTS = 'monthly_copy_30_contracts',
    MONTHLY_COPY_100_CONTRACTS = 'monthly_copy_100_contracts',

    REPEAT_COMPLETE_LABEL_TASK = 'repeat_complete_label_task',
    DAILY_COMPLETE_LABEL_TASK = 'daily_complete_label_task',
    REPEAT_REFER_NEW_USER = 'repeat_refer_new_user',
    REPEAT_REFER_BONUS = 'repeat_refer_bonus',
    REPEAT_EARN_BADGE = 'repeat_earn_badge',
    REPEAT_AGENT_INTERACTION = 'repeat_agent_interaction',
}

export const PointTaskNameMap = {
    [PointTask.ONE_TIME_SIGNUP]: 'Sign up',
    [PointTask.ONE_TIME_ADD_EMAIL]: 'Add email',
    [PointTask.ONE_TIME_UPDATE_PROFILE]: 'Update display name',
    [PointTask.ONE_TIME_VERIFY_CONTRACT]: 'Verify a smart contract',
    [PointTask.ONE_TIME_CLAIM_BADGE]: 'Get a badge',
    [PointTask.REPEAT_EARN_BADGE]: 'Get a badge',

    [PointTask.DAILY_LOGIN]: 'Log in',
    [PointTask.DAILY_FOLLOW]: 'Follow a wallet',
    [PointTask.DAILY_LIKE]: 'Like',
    [PointTask.DAILY_CHAT]: 'DM to a wallet',

    [PointTask.MONTHLY_LOGIN_7_DAYS]: 'Log in for 7 days',
    [PointTask.MONTHLY_LOGIN_14_DAYS]: 'Log in for 14 days',
    [PointTask.MONTHLY_LOGIN_28_DAYS]: 'Log in for 28 days',
    [PointTask.MONTHLY_COPY_10_CONTRACTS]: 'Smart contract copied 10 times',
    [PointTask.MONTHLY_COPY_30_CONTRACTS]: 'Smart contract copied 30 times',
    [PointTask.MONTHLY_COPY_100_CONTRACTS]: 'Smart contract copied 100 times',
    [PointTask.REPEAT_COMPLETE_LABEL_TASK]: 'Complete a Labeling Task Set',
    [PointTask.DAILY_COMPLETE_LABEL_TASK]: 'Daily Complete a Labeling Task Set',
    [PointTask.REPEAT_REFER_NEW_USER]: 'Refer a User',
    [PointTask.REPEAT_REFER_BONUS]: 'Refer Bonus',
    [PointTask.REPEAT_AGENT_INTERACTION]: 'Completed an Agent Task',
};

export interface PointTaskApiInfo extends BaseInfo {
    id: string;
    user_id: string;
    section: string;
    task_type: PointTask;
    is_finalized: boolean;
    note: string;
    points: number;
    points_awarded: number;
    reference_id: string;
    progress: number;
    created_at: string;
    updated_at: string;
    reference_name: string;
}
