import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MyProfileService } from '@src/app/modules/my-profile/my-profile.service';
import { CommonService } from '@src/app/services/common.service';
import { EthersRequestService } from '@src/app/services/ethers-request.service';
import { WalletStatusService } from '@src/app/services/wallet-status.service';
import { BadgeApiInfo } from '../../types/badge.type';
import { WalletEnum } from '../../types/wallet-chain.type';

@Component({
    selector: 'app-badge',
    templateUrl: './badge.component.html',
    styleUrls: ['./badge.component.less'],
})
export class BadgeComponent {
    @Input() badge: BadgeApiInfo;

    minting = false;

    constructor(
        private commonService: CommonService,
        private walletStatusService: WalletStatusService,
        private ethersRequest: EthersRequestService,
        private router: Router,
        private myProfileService: MyProfileService
    ) {}

    goContract() {
        if (this.badge.contract_address && this.badge.chain) {
            if (this.badge.is_earned) {
                this.router.navigate(['/community/contract', this.badge.chain, this.badge.contract_address]);
            } else {
                this.mintBadge(this.badge.id);
            }
        }

        if (['newbie', 'og', 'ranking'].includes(this.badge.id) && this.badge.section === 'DAY Mining') {
            this.router.navigate(['/hemera', 'agent', 'mining-days']);
        }
    }

    mintBadge(badge: any) {
        // 如果是在手机浏览器，不是在钱包浏览器，就提示用户去钱包浏览器
        if (this.commonService.isMobile && this.walletStatusService.walletType === WalletEnum.WALLET_CONNECT) {
            return this.commonService.error('Please open in wallet browser');
        }

        this.minting = true;

        this.ethersRequest
            .mintBadge(badge)
            .then((data: any) => {
                if (data.earned_badges?.length) {
                    this.commonService.showGotNewBadgesModal(data.earned_badges);
                }
                this.myProfileService.getAllBadges();
            })
            .catch(err => {
                console.log(err);
                if (err.shortMessage) {
                    this.commonService.error(err.shortMessage);
                } else {
                    this.commonService.errorMessageByResponse(err);
                }
            })
            .finally(() => (this.minting = false));
    }
}
