import { isPlatformBrowser, ViewportScroller } from '@angular/common';
import { AfterViewInit, Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router, Scroll } from '@angular/router';
import { environment } from '@src/environments/environment';
import PullToRefresh from 'pulltorefreshjs';
import { filter } from 'rxjs';
import { CommonService } from './services/common.service';
import { EthersRequestService } from './services/ethers-request.service';
import { GtagService } from './services/gtag.service';
import { RouterChangeMonitorService } from './services/router-change-monitor.service';

declare global {
    interface Window {
        dataLayer: any[];
        gtag: any;
    }
}

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less'],
})
export class AppComponent implements AfterViewInit, OnInit {
    title = 'w3w-scan';

    hideHeader = false;
    hideFooter = false;

    constructor(
        private router: Router,
        private viewportScroller: ViewportScroller,
        @Inject(PLATFORM_ID) private platformId: any,
        private gtagService: GtagService,
        private commonService: CommonService,
        private routerMonitor: RouterChangeMonitorService,
        private ethersRequest: EthersRequestService
    ) {}

    ngOnInit() {
        if (environment.production && isPlatformBrowser(this.platformId)) {
            this.gtagService.initializeGtag();
        }

        this.ethersRequest.initProvider();

        this.routerMonitor.setupRouterEvents();

        // 全局设置页面前进，后退时的恢复原先位置的动画
        this.router.events.pipe(filter(event => event instanceof Scroll)).subscribe(route => {
            const typedRoute: Scroll = route as Scroll;

            const currentPath = typedRoute.routerEvent?.url?.split('#')?.[0];
            const previousPath = this.routerMonitor.previousPath?.split('#')?.[0];

            if (currentPath === previousPath) {
                return;
            }

            if (typedRoute.position) {
                // Back
                setTimeout(() => {
                    this.viewportScroller.scrollToPosition(typedRoute.position);
                }, 10);
            } else {
                // Forward
                setTimeout(() => {
                    this.viewportScroller.scrollToPosition([0, 0]);
                }, 10);
            }
        });

        // 监听路由事件
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
            // 设置页面信息
            this.commonService.setPageInfo();

            if (
                event.url?.startsWith('/home') ||
                (this.commonService.isMobile && event.url?.startsWith('/hemera/agent/mining-days')) ||
                (this.commonService.isMobile && event.url?.startsWith('/hemera/mining-rules')) ||
                event.url?.startsWith('/tg-landing')
            ) {
                this.hideHeader = true;
            } else {
                this.hideHeader = false;
            }

            if (
                event.url?.startsWith('/task/') ||
                event.url?.startsWith('/hemera/agent/') ||
                event.url?.startsWith('/hemera/agents') ||
                event.url?.startsWith('/my-profile') ||
                event.url?.startsWith('/tg-landing')
            ) {
                this.hideFooter = true;
            } else {
                this.hideFooter = false;
            }
        });
    }

    ngAfterViewInit() {
        this.checkAndSetWidgetStyle();
        // this.setupPullToRefresh();

        // Setup vh on mobile
        this.setupVH();
    }

    setupVH() {
        function setHeight() {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        }

        setHeight();
        window.addEventListener('resize', setHeight);
    }

    checkAndSetWidgetStyle() {
        if (isPlatformBrowser(this.platformId)) {
            const chatComponent = document.querySelector('chat-component');
            chatComponent.setAttribute('style', 'display: block');
            const targetElement = chatComponent.shadowRoot?.querySelector('.chat_widget_btn') as HTMLDivElement;

            if (targetElement) {
                targetElement.style.display = 'none';
            } else {
                setTimeout(() => {
                    this.checkAndSetWidgetStyle();
                }, 1000);
            }
        }
    }

    setupPullToRefresh() {
        const standalone = (navigator as any).standalone || window.matchMedia('(display-mode: standalone)').matches;
        if (!standalone) {
            return; // not standalone; no pull to refresh needed
        }

        PullToRefresh.init({
            onRefresh() {
                location.reload();
            },
        });
    }
}
