import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { InscriptionProtocol } from '@src/app/modules/inscription/inscription.service';
import { CommonService } from '@src/app/services/common.service';
import { ChainEnum, getChainImg, getChainName, getChainSymbolIconByChainName } from '@src/app/shared/types/wallet-chain.type';
import { AddressItem, CommunityItem, ContractItem, TxnItem } from '../search-input/search-input.service';

@Component({
    selector: 'app-search-result',
    templateUrl: './search-result.component.html',
    styleUrls: ['./search-result.component.less'],
})
export class SearchResultComponent implements OnChanges {
    @Input() data: any[];
    @Input() mode: 'desktop' | 'mobile' = 'desktop';
    @Output() clickItem = new EventEmitter();

    getChainSymbolIconByChainName = getChainSymbolIconByChainName;

    selectedTab: 'address' | 'transaction' | 'community' | 'contract' | 'company' | 'user' | 'inscription';
    addressList: AddressItem[];
    txnList: TxnItem[];
    communityList: CommunityItem[];
    contractList: ContractItem[];
    userList: { type: 'user'; user_display_name: string; user_id: string; user_name: string; user_profile_image: string }[];
    companyList: { company_name: string; company_id: string; company_logo: string; type: 'company' }[];
    inscriptionList: {
        name: string;
        ticker: string;
        total_supply: number;
        protocol?: InscriptionProtocol;
        deployed_block_timestamp: string;
        chain: ChainEnum;
        type: 'token';
    }[];

    constructor(public commonService: CommonService, private router: Router) {}

    ngOnChanges(e: SimpleChanges): void {
        const data = e['data'];
        if (data.currentValue) {
            this.addressList = [];
            this.txnList = [];
            this.communityList = [];
            this.contractList = [];
            this.companyList = [];
            this.userList = [];
            this.inscriptionList = [];

            data.currentValue.forEach((item: any) => {
                if (item.type === 'address') {
                    this.addressList.push(item);
                    if (!this.commonService.addressIconMap.has(item.wallet_address)) {
                        this.commonService.setAddressIconMap([item.wallet_address]);
                    }
                } else if (item.type === 'transaction') {
                    this.txnList.push(item);
                } else if (item.type === 'community') {
                    this.communityList.push(item);
                } else if (item.type === 'company') {
                    this.companyList.push(item);
                } else if (item.type === 'user') {
                    this.userList.push(item);
                } else if (item.type === 'token') {
                    this.inscriptionList.push(item);
                } else {
                    this.contractList.push(item);
                }
            });
        }
    }

    go(link: string) {
        this.clickItem.emit();
        setTimeout(() => {
            this.router.navigateByUrl(link);
        }, 10);
    }

    getChainIcon(chain: ChainEnum) {
        return getChainImg(chain);
    }

    getChainName(chain: ChainEnum) {
        return getChainName(chain);
    }
}
