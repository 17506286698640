import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import localforage from 'localforage';
import { WalletStatusService } from './wallet-status.service';

interface TgUserData {
    id: number;
    first_name: string;
    last_name: string;
    username: string;
    language_code: string;
    allows_write_to_pm: boolean;
    photo_url: string;
}

interface TGUnSafeUserData {
    query_id: string;
    user: TgUserData;
    auth_date: string;
    hash: string;
}

enum AccountType {
    ONLY_TG = 'tg',
    ONLY_WALLET = 'wallet',
    TG_WALLET = 'tg_wallet',
}

@Injectable({
    providedIn: 'root',
})
export class TelegramService {
    tgUserData: TgUserData | null = null;

    get isInTelegramWebApp() {
        return !!this.tgUserData;
    }

    get accountType(): AccountType {
        if (this.walletStatusService.isWalletConnected && this.isInTelegramWebApp) {
            return AccountType.TG_WALLET;
        }

        if (this.isInTelegramWebApp) {
            return AccountType.ONLY_TG;
        }

        return AccountType.ONLY_WALLET;
    }
    get isOnlyTg() {
        return this.accountType === AccountType.ONLY_TG;
    }
    get isOnlyWallet() {
        return this.accountType === AccountType.ONLY_WALLET;
    }
    get isTgWallet() {
        return this.accountType === AccountType.TG_WALLET;
    }

    private ready: Promise<void>;

    constructor(private router: Router, private walletStatusService: WalletStatusService) {
        this.ready = this.initialize();
        this.setupRouterChangeMonitor();
    }

    private async initialize(): Promise<void> {
        // 等待 SDK 加载
        while (!this.isTelegramWebAppAvailable()) {
            await new Promise(resolve => setTimeout(resolve, 100));
        }

        this.setupUserInfo();
        this.setupBackButton();
    }

    private isTelegramWebAppAvailable(): boolean {
        return !!window.Telegram?.WebApp;
    }

    private async setupUserInfo() {
        const cachedTgUserData = await localforage.getItem('socialscan_tg_user_data');
        this.tgUserData = (window.Telegram.WebApp.initDataUnsafe as TGUnSafeUserData)?.user;

        if (cachedTgUserData && !this.tgUserData) {
            this.tgUserData = cachedTgUserData as TgUserData;
            localforage.setItem('socialscan_tg_user_data', this.tgUserData);
            return;
        }
    }

    private setupBackButton() {
        window.Telegram.WebApp.BackButton.onClick(() => {
            // TODO: back to previous page
            this.router.navigate(['/my-profile']);
        });
    }

    private setupRouterChangeMonitor() {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                // if not in /my-profile page, show back button
                if (!event.url.startsWith('/my-profile')) {
                    window.Telegram.WebApp.BackButton.show();
                } else {
                    window.Telegram.WebApp.BackButton.hide();
                }
            }
        });
    }

    // 使用此方法确保 SDK 已加载
    async ensureReady(): Promise<void> {
        await this.ready;
    }
}
