import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { WalletStatusService } from '@src/app/services/wallet-status.service';
import { LOCAL_STORAGE_KEY_ENUM } from '../types/storage.type';

@Injectable({
    providedIn: 'root',
})
export class ProfileGuard {
    constructor(private walletStatusService: WalletStatusService, private router: Router) {}

    async canActivate() {
        if (this.walletStatusService.userProfile) {
            return true;
        }

        return this.walletStatusService.getWalletProfile().then(() => {
            const tokenStr = localStorage.getItem(LOCAL_STORAGE_KEY_ENUM.TOKEN);

            if (!this.checkIfSignupCompleted(tokenStr)) {
                this.router.navigate(['/', 'invite'], { queryParamsHandling: 'merge' });
            }

            return true;
        });
    }

    checkIfSignupCompleted(token: string) {
        if (!token) return false;
        try {
            const decodedInfo = this.walletStatusService.decodeJWT(token);
            return decodedInfo?.registration_complete;
        } catch (err) {
            return false;
        }
    }
}
