import { ChainEnum } from '../types/wallet-chain.type';

export async function readFileContentFromUrl(url: string) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<string>(async (resolve, reject) => {
        const reader = new FileReader();
        const res = await fetch(url).then(data => data.text());
        reader.readAsText(new Blob([res]));

        reader.onload = function (event) {
            const fileContent = event.target.result.toString();
            resolve(fileContent);
        };

        reader.onerror = function (event) {
            reject(event);
        };
    });
}

/**
 * Get token image url
 * @param chain ChainEnum
 * @param contractAddr contract address
 * @param tokenId token id
 * @param imgOptions {width: number, format: string}
 * @returns token image url
 */
export function tokenImage(
    chain: ChainEnum,
    contractAddr: string,
    tokenId: any,
    imgOptions?: {
        width?: number;
        format?: string;
    }
) {
    const mixedOptions = {
        width: 300,
        format: 'auto',
        ...(imgOptions || {}),
    };

    return `https://d2f2yv05kppy9k.cloudfront.net/${chain}_socialscan/${contractAddr?.toLowerCase()}/${tokenId}?format=${mixedOptions.format}&width=${
        mixedOptions.width
    }`;
}
