import { Component, Optional } from '@angular/core';
import { CommonService } from '@services/common.service';
import { WalletStatusService } from '@services/wallet-status.service';
import { WalletEnum } from '@shared/types/wallet-chain.type';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { NzModalRef } from 'ng-zorro-antd/modal';

import { Router } from '@angular/router';
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
import { TelegramService } from '@src/app/services/telegram.service';
import { BrowserProvider } from 'ethers';
import { filter } from 'rxjs';

declare global {
    interface Window {
        signWithDevKey?: any;
    }
}

@Component({
    selector: 'app-connect-wallet-modal',
    templateUrl: './connect-wallet-modal.component.html',
    styleUrls: ['./connect-wallet-modal.component.less'],
})
export class ConnectWalletModalComponent {
    connectingWallet: WalletEnum;

    WalletEnum = WalletEnum;

    sdnClient: any;
    sdnMsgParams: { did: string; message: string; random_server: any; updated: any };

    wallets = this.walletStatusService.detectWalletsBasedOnDevice();

    get prefix() {
        return this.walletStatusService.prefix;
    }

    constructor(
        @Optional() public modalRef: NzModalRef,
        @Optional() public drawerRef: NzDrawerRef,
        private commonService: CommonService,
        private walletStatusService: WalletStatusService,
        private router: Router,
        public tgService: TelegramService
    ) {}

    async connect(type: WalletEnum) {
        this.sdnClient = (window as any).chatWidgetApi?._client;

        try {
            switch (type) {
                case WalletEnum.WALLET_CONNECT:
                    await this.connectWalletConnect();
                    break;

                case WalletEnum.COINBASE:
                    await this.connectCoinBase();
                    break;

                case WalletEnum.META_MASK:
                    await this.connectMetaMask();
                    break;

                case WalletEnum.TRUST_WALLET:
                    await this.connectTrustWallet();
                    break;
            }
        } catch (err: any) {
            if (err.code === -30002) {
                this.commonService.error(err.message || null);
            }
        }
    }

    async connectWalletConnect() {
        this.connectingWallet = WalletEnum.WALLET_CONNECT;

        try {
            this.close();
            this.walletStatusService.web3Modal.subscribeProvider(async state => {
                if (state.isConnected && !this.walletStatusService.currentWallet.getValue()) {
                    const accounts = [state.address];

                    const sdnData = await this.getSdnSignMsg(accounts[0]);
                    if (!sdnData) {
                        // TODO: Error Message
                        return;
                    }

                    const tempProvider = new BrowserProvider(this.walletStatusService.web3Modal.getWalletProvider());
                    const sign: string = await tempProvider.send('personal_sign', [sdnData.message, accounts[0]]);
                    this.walletStatusService.setSessionData(WalletEnum.WALLET_CONNECT, accounts[0], sign);
                    this.goInvitation();
                } else {
                    // TODO:
                }
            });

            this.walletStatusService.web3Modal.open();

            // this.close();
        } catch (err: any) {
            this.commonService.error(err.message);
            this.connectingWallet = null;
        }
    }

    async connectCoinBase() {
        // Initialize Coinbase Wallet SDK
        this.connectingWallet = WalletEnum.COINBASE;
        const coinbaseWallet = new CoinbaseWalletSDK({
            appName: 'SocialScan',
            darkMode: false,
        });

        const ethereum = coinbaseWallet.makeWeb3Provider('https://mainnet.infura.io/v3/005233ef0d61464a98a17a1230d7c59a', 1);
        try {
            const accounts = await ethereum.send('eth_requestAccounts', []);
            const sdnData = await this.getSdnSignMsg(accounts[0]);
            if (!sdnData) {
                this.connectingWallet = null;
                // TODO: Error Message
                return;
            }

            const sign = await ethereum.send('personal_sign', [sdnData.message, accounts[0]]);
            this.walletStatusService.setSessionData(WalletEnum.COINBASE, accounts[0], sign);

            this.connectingWallet = null;
            this.close();
            this.goInvitation();
        } catch (err: any) {
            this.commonService.error(err.message);
            this.connectingWallet = null;
        }
    }

    async connectMetaMask() {
        this.connectingWallet = WalletEnum.META_MASK;
        let mmProvider = window.ethereum as any;

        if (mmProvider?.providers?.length) {
            mmProvider = mmProvider.providers?.find((item: any) => item.isMetaMask);
        }

        if (!mmProvider) {
            if (this.commonService.isMobile) {
                // eslint-disable-next-line max-len
                window.location.href = `https://metamask.app.link/dapp/${window.location.protocol}//${window.location.host}/${window.location.pathname}`;
            } else {
                window.open('https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn', '_blank');
            }
            this.connectingWallet = null;
            return;
        }

        try {
            const accounts = await mmProvider.request({ method: 'eth_requestAccounts', params: [] });
            const sdnData = await this.getSdnSignMsg(accounts[0]);
            if (!sdnData) {
                this.connectingWallet = null;
                this.commonService.error('Get signature failed , please refresh the page and try again.');
                return;
            }
            const sign = await mmProvider.request({ method: 'personal_sign', params: [sdnData.message, accounts[0]] });
            this.walletStatusService.setSessionData(WalletEnum.META_MASK, accounts[0], sign);
            this.close();
            await this.goInvitation();
            this.connectingWallet = null;
        } catch (err: any) {
            this.commonService.error(err?.message);
            this.connectingWallet = null;
        }
    }

    async connectTrustWallet() {
        const trustWalletProvider = this.walletStatusService.getTrustWalletFromWindow();
        this.connectingWallet = WalletEnum.TRUST_WALLET;

        if (!trustWalletProvider) {
            if (this.commonService.isMobile) {
                // eslint-disable-next-line max-len
                window.location.href = `https://link.trustwallet.com/open_url?url=${window.location.protocol}//${window.location.host}/${window.location.pathname}&coin_id=60`;
            } else {
                window.open('https://chrome.google.com/webstore/detail/trust-wallet/egjidjbpglichdcondbcbdnbeeppgdph', '_blank');
            }
            this.connectingWallet = null;
            return;
        }

        try {
            const accounts = await trustWalletProvider.request({ method: 'eth_requestAccounts' });
            const sdnData = await this.getSdnSignMsg(accounts[0]);
            const sign = await trustWalletProvider.request({
                method: 'personal_sign',
                params: [sdnData.message, accounts[0]],
            });
            this.walletStatusService.setSessionData(WalletEnum.TRUST_WALLET, accounts[0], sign);
            this.close();
            await this.goInvitation();
            this.connectingWallet = null;
        } catch (err: any) {
            this.commonService.error(err.message);
            this.connectingWallet = null;
        }
    }

    async getSdnSignMsg(address: string) {
        const msg = this.walletStatusService.getMessageToSign();
        this.walletStatusService.msgToSign = msg;

        return {
            message: msg,
            updated: null,
            random_server: null,
            did: null,
        };
        if (!this.sdnClient) return null;

        const {
            data: [did],
        } = await this.sdnClient.getDIDList(address);
        const preLoginParams = did ? { did } : { address: this.walletStatusService.prefix + address };
        const { message, updated, random_server } = await this.sdnClient.preDiDLogin1(preLoginParams);
        this.walletStatusService.msgToSign = message;
        return { message, updated, random_server, did };
    }

    getMessageToSign() {
        return `Login with this account\n\ntime: ${new Date().toISOString()}\n${this.walletStatusService.generateRandomHex(32)}`;
    }

    close() {
        this.modalRef?.triggerCancel();
        this.drawerRef?.close();
    }

    async goInvitation() {
        return new Promise(() => {
            const sub = this.walletStatusService.tokenStr$.pipe(filter(res => !!res)).subscribe(() => {
                if (this.walletStatusService.checkIfSignupCompleted()) {
                    sub.unsubscribe();
                    this.router.navigate(['/', 'my-profile', 'dashboard']);
                } else {
                    this.router.navigate(['/', 'invite'], { queryParamsHandling: 'merge' });
                }
            });
        });
    }
}
